.accordion-panel {
  &__header {
    color: $generic-grey-color-1 !important;

    .usb-icon svg {
      fill: $generic-grey-color-1 !important;
    }
  }

  &__content {
    max-height: none;
  }

  &:first-of-type {
    border-bottom: none;
  }
}