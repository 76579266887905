.usb-button {
    font-family: $brand-font-base !important;

    &.button {
        &--standard {
            background-color: $button-primary-background-color;

            &--heavy {
                &:hover {
                    background: $token-background-gradient-interaction !important;
                    color: $button-primary-hover-text-color !important;  
                }
    
                &:focus {
                    background: $token-background-focus-primary !important;
                }
    
                &:active {
                    background: $token-background-button-interaction !important;
                    color: $button-primary-active-text-color !important;
                }

                .svg-icon {
                    color: $token-color-grey-90 !important;
                }
            }

            &--subtle {
                border-color: $button-secondary-border-color !important;
                color: $token-text-color-brand-primary !important;

                &:hover {
                    background: $token-background-gradient-interaction !important;
                    color: $button-primary-hover-text-color !important;    
                }
    
                &:focus {
                    background: $token-background-focus-secondary !important;
                    color: $token-text-color-brand-primary !important;
                }
    
                &:active {
                    background: $token-background-button-interaction !important;
                    color: $button-primary-active-text-color !important;
                }
            }
        }

        &--highlight {
            border-color: $button-secondary-border-color !important;
            color: $token-text-color-brand-primary !important;

            &--heavy {
                background: $token-color-brand-primary !important;
                &:hover {
                    background: $token-background-gradient-interaction !important;
                    color: $button-secondary-hover-text-color !important;  
                }

                &:active {
                    background: $token-background-button-interaction !important;
                    color: $button-secondary-active-text-color !important;
                }
            }

            &--subtle {
                &:hover {
                    background: $token-background-gradient-interaction !important;
                    color: $button-secondary-hover-text-color !important;  
                }
    
                &:active {
                    background: $token-background-button-interaction !important;
                    color: $button-secondary-active-text-color !important;
                }
            }
        }

        &--loud {
            background: $edp-button-loud-background-color;

            &:hover {
                background: $token-background-gradient-interaction !important;
                color: $button-secondary-hover-text-color !important;   
            }

            &:active {
                background: $token-background-button-interaction !important;
                color: $button-secondary-active-text-color !important;
            }
        }

        &--small {
            padding: $token-spacing-2 $token-spacing-6 !important;
        }


        &:focus, &:active {
            &::before{
                border: $token-border-width-large solid $token-border-focus;
            }
        }

    }
}

#logoff-link {
    &:hover {
        background: $token-background-primary !important;
        color: $token-text-color-interaction !important;
    }
}

#close-login-modal {
    &:focus, &:hover{
        background: $button-utility-hover-icon-fill-color !important;
    }
}

// .usb-button:focus:before {
//     border: none !important;
// }