.usb-tabs {
    background: none !important;

    .tabs__button {
        color: $generic-grey-color-1 !important;
    }

    .tabs__button[aria-selected=true] {
        color: $generic-grey-color-1 !important;

        &::after {
            background-color: $generic-grey-color-1 !important;
        }
    }
}