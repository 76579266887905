/* Fonts */
html, body {
  font-family: $brand-font-base;
}
// iOS default font-size is 17px and accessible text needs this font setting to properly scale. Additionally, we need to reset the font-size to 16px from 17px.
@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body;
  }
  body
  {
    font-size: 0.94rem;
  }
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property. You may not attempt to copy, install, redistribute, convert, modify or
reverse engineer this font software.
*/

// Font Sources
$usb-light-font-files: (
light: ( 
  weight: 300, 
  style: 'normal', 
  woff2: "U.S.BankCircularWeb-Light.woff2", 
  woff: "U.S.BankCircularWeb-Light.woff"
),
light-italic: ( 
  weight: 300, 
  style: 'italic', 
  woff2: "U.S.BankCircularWeb-LightItalic.woff2", 
  woff: "U.S.BankCircularWeb-LightItalic.woff"
),
regular: ( 
  weight: 400, 
  style: 'normal', 
  woff2: "U.S.BankCircularWeb-Regular.woff2", 
  woff: "U.S.BankCircularWeb-Regular.woff"
),
regular-italic: (
  weight: 400,
  style: 'italic',
  woff2: "U.S.BankCircularWeb-Italic.woff2",
  woff: "U.S.BankCircularWeb-Italic.woff"
),
book: ( 
  weight: 450, 
  style: 'normal', 
  woff2: "U.S.BankCircularWeb-Book.woff2", 
  woff: "U.S.BankCircularWeb-Book.woff"
),
book-italic: (
  weight: 450,
  style: 'italic',
  woff2: "U.S.BankCircularWeb-BookItalic.woff2",
  woff: "U.S.BankCircularWeb-BookItalic.woff"
),
medium: (
  weight: 500,
  style: 'normal',
  woff2: "U.S.BankCircularWeb-Medium.woff2", 
  woff: "U.S.BankCircularWeb-Medium.woff"
),
medium-italic: (
  weight: 500,
  style: 'italic',
  woff2: "U.S.BankCircularWeb-MediumItalic.woff2", 
  woff: "U.S.BankCircularWeb-MediumItalic.woff"
),
bold: ( 
  weight:700,
  style: 'normal',
  woff2: "U.S.BankCircularWeb-Bold.woff2", 
  woff: "U.S.BankCircularWeb-Bold.woff"
),
bold-italic: (
  weight: 700,
  style: 'italic',
  woff2: "U.S.BankCircularWeb-BoldItalic.woff2", 
  woff: "U.S.BankCircularWeb-BoldItalic.woff"
),
black: ( 
  weight:900,
  style: 'normal',
  woff2: "U.S.BankCircularWeb-Black.woff2", 
  woff: "U.S.BankCircularWeb-Black.woff"
),
black-italic: (
  weight: 900,
  style: 'italic',
  woff2: "U.S.BankCircularWeb-BlackItalic.woff2", 
  woff: "U.S.BankCircularWeb-BlackItalic.woff"
)
);


@each $font, $value in $usb-light-font-files {
  // #${ font }
  @font-face{
    font-family:"Circular-USB";
    src:url("https://content.usbank.com/content/dam/onlinebanking/common/static/fonts/#{ map-get($value, woff2 ) }") format("woff2"), url("https://content.usbank.com/content/dam/onlinebanking/common/static/fonts/#{ map-get( $value, woff ) }") format("woff");
    font-weight: #{ map-get($value, weight) };
    font-style: #{ map-get($value, style) };
    font-display:swap;
  }
  }
  