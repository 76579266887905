.usb-grid--container {
    max-width: 100% !important;

    .leftSideGrid {
        display: $displayViews-leftSideGrid-display !important;

        &.grid.normal-gap {
            grid-column-gap: $token-spacing-0 !important;
            grid-row-gap: $token-spacing-0 !important;
            padding: $token-spacing-0 $token-spacing-4 !important;
        }
    }

    .wrapperFullWidth {
        &.grid.normal-padding {
            padding: $token-spacing-0 $token-spacing-4 !important;
        }
    }
    
    @media (min-width: $token-breakpoint-x-large) { 
        .grid.normal-padding {
            padding: $token-spacing-0 calc(5.55556vw - 1.66667rem) !important;
        }
      }
}
