@import "variables.scss";

// theme fonts
@import "fonts.scss";
@import "./fonts-typography.scss";

// default reset and mixins
@import "@usb-shield/themes/dist/library/styles/reset.scss";

// mixins
@import "mixins.scss";

//scss overrides
@import "button";
@import "usb-tabs";
@import "usb-grid";
@import "usb-accordion";
@import "code-block";

// Override LoginWidget styles
.rememberCheckbox, #enrollmentLink, #test-aw-forgot-personal-id {
  visibility: hidden !important;
  display: none !important;
}

#login-button-continue {
  background: $token-color-brand-primary;
}

#test-aw-forgot-password {
  color: $token-color-brand-primary
}


